// @mui
import { styled } from '@mui/material/styles';

const StyledRoot = styled('div')({
  display: 'flex',
  minHeight: '100%',
  overflow: 'hidden',
  flexDirection:'column'
});

// ----------------------------------------------------------------------

export default function DashboardLayout() {

  return (
    <StyledRoot>
        <div style={{marginTop:'40px',backgroundColor:'#2e6946',width:'100%',height:'100px',paddingLeft:'150px'}}>
          <p style={{fontSize:'30px',color:'#ffffff'}}>Budee Solutions Inc.</p>
        </div>
        <div style={{paddingLeft:'150px'}}>
          <p style={{fontSize:'20px',fontWeight:'bold'}}>Want to Learn More?</p>
          <div style={{display:'flex',flexDirection:'row',alignItems:'center',marginLeft:'50px'}}>
            <div style={{width:'250px'}}>
              <img width="150px" src="/assets/youtube-logo.png" alt="YouTube logo" />
            </div>
            <a href="https://www.youtube.com/@BudeeSolutions">https://www.youtube.com/@BudeeSolutions</a>
          </div>
          <div style={{display:'flex',flexDirection:'row',alignItems:'center',marginLeft:'50px'}}>
            <p style={{width:'250px'}}>Technical Documentation:</p>
            <a href="https://training.budeesolutions.com/">https://training.budeesolutions.com/</a>
          </div>
        </div>
        <div style={{paddingLeft:'150px'}}>
          <p style={{fontSize:'20px',fontWeight:'bold'}}>Contact:</p>
          <div style={{marginLeft:'50px'}}>
            <p>sales@budeesolutions.com</p>
            <p>info@budeesolutions.com</p>
            <a href="https://budeesolutions.com/contact-us/">https://budeesolutions.com/contact-us/</a>
          </div>
        </div>
        <div style={{paddingLeft:'150px'}}>
          <p style={{fontSize:'20px',fontWeight:'bold'}}>Start Your Free Trial:</p>
          <div style={{marginLeft:'50px'}}>
            <a href="https://app.budeesolutions.com/login">https://app.budeesolutions.com/login</a>
          </div>
        </div>
    </StyledRoot>
  );
}
