// @mui
import { styled } from '@mui/material/styles';


// ----------------------------------------------------------------------
const StyledRoot = styled('div')({
  display: 'flex',
  minHeight: '100%',
  overflow: 'hidden',
  flexDirection:'column'
});

// ----------------------------------------------------------------------

export default function DashboardLayout() {

  return (
    <StyledRoot>
        <div style={{marginTop:'40px',backgroundColor:'#2e6946',width:'100%',height:'100px',paddingLeft:'150px'}}>
          <p style={{fontSize:'30px',color:'#ffffff'}}>Budee Solutions Inc.</p>
        </div>
        <div style={{paddingLeft:'150px'}}>
          <p style={{fontSize:'40px',fontWeight:'bold'}}>Registering for Free Trial</p>
          <ol style={{fontSize:'30px',marginLeft:'50px'}}>
            <li>Register Your User Account</li>
            <li>Add Your Company Account</li>
            <li>Add a Project Location</li>
          </ol>
        </div>
    </StyledRoot>
  );
}
